// TODO: REFACTORING
import React, { Fragment } from 'react';
import { useSpring, animated } from 'react-spring';
import '@reach/tooltip/styles.css';
import P from 'prop-types';

import * as Styled from './AnimatedFloatingButton.style';
import FloatingButton from '@components/FloatingButton';

animated.FloatingButton = animated(FloatingButton);

const AnimatedFloatingButton = ({ icon, label, onClick }) => {
  const [{ x, color, backgroundColor }, setValues] = useSpring(() => ({
    x: 100,
    color: '#ffffff',
    backgroundColor: '#ffffffb5',
  }));

  const style = {
    position: 'fixed',
    zIndex: 19,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    top: '50%',
    right: '50px',
    opacity: 1,
    padding: '10px',
    width: '300px',
    height: 'auto',
    background: '#ffffffb5 0% 0% no-repeat padding-box',
    backgroundColor: backgroundColor.interpolate(v => `${v}`),
    color: color.interpolate(v => `${v}`),
    borderRadius: '34px',
    boxShadow: '0px 3px 20px #00000022',
    transform: x.interpolate(v => `translateX(${v}%`),
    fill: 'black',
  };

  const handleClick = () => {
    if (typeof onClick === 'function') onClick();
  };

  return (
    <Fragment>
      <Styled.ButtonContainer
        onMouseEnter={() =>
          setValues({ x: 50, color: '#fff', backgroundColor: '#00897b' })
        }
        onMouseLeave={() =>
          setValues({ x: 100, color: '#ffffff', backgroundColor: '#ffffffb5' })
        }
        onClick={handleClick}
      >
        <animated.FloatingButton
          icon={icon}
          label={label}
          style={style}
          className="glance"
        />
      </Styled.ButtonContainer>
    </Fragment>
  );
};

AnimatedFloatingButton.propTypes = {
  icon: P.string,
  label: P.string,
  onClick: P.func,
};

export default AnimatedFloatingButton;

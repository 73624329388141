// TODO: REFACTORING
import React, { useState, useEffect, useRef } from 'react';
import * as Styled from './LocaleOptions.style';
import { navigate } from 'gatsby';
import { useParams } from '@reach/router';

import { useSelector } from 'react-redux';
import { useStore } from 'react-redux';
import { setLanguage, setCurrency } from '@store/reducers/general/operations';

import { useCurrentLang } from '@utils/translate';
//import { getLangFromLocalStorage } from '@utils/language';
import { getLangByCode } from '@utils/language';
import {
  getAllCurrencies,
  getCurrencyByCode,
} from '@gatsby-local-plugin/flapper-gatsby-currencies/';
import { translate as t } from '@utils/translate';

import DropDown from '@components/DropDown';

const LocaleOptions = ({
  translations,
  queryLanguages,
  darkFont,
  onSubmit,
  blockLocaleOptions = {
    currency: false,
    language: false,
  },
}) => {
  const ref = useRef(null);
  const urlParams = useParams();
  const store = useStore();
  const [menuOpen, setMenuOpen] = useState(false);
  const TEXTS = {
    CURRENCY_LABEL: t('currency'),
    LANGUAGE_LABEL: t('language'),
    CONFIRM: t('confirmButton'),
  };

  // LANGUAGE
  const [urlQueryString, setUrlQueryString] = useState(null);
  const currentLang = useCurrentLang();
  const [languageList, setLanguageList] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState({
    icon: '',
    lang: 'pt_BR',
    shortLabel: 'PT',
    index: 0,
  });
  const translationsPaths = translations.reduce(
    (obj, { polylang_current_lang, path }) => {
      obj[polylang_current_lang] = `${path}`;
      return obj;
    },
    {}
  );

  // CURRENCY
  const [currencyList, setCurrencyList] = useState([]);
  const currentCurrency = useSelector(state => state.general.currency);
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const handleSelectLanguage = (item, index) => {
    setSelectedLanguage({
      icon: item.icon,
      lang: item.lang,
      shortLabel: item.shortLabel,
      index,
    });
  };

  const handleSelectCurrency = (item, index) => {
    setSelectedCurrency({
      code: item.code,
      index,
    });
  };

  // Solving SSR compatibility
  useEffect(() => {
    const langsList = Object.keys(queryLanguages).map((lang, index) => {
      return {
        icon: queryLanguages[lang].header_lang_icon,
        label: queryLanguages[lang].header_lang_text,
        shortLabel: queryLanguages[lang].header_lang_short_text.split('-')[0],
        lang: queryLanguages[lang].header_lang_slug,
        index,
      };
    });
    const currencies = getAllCurrencies().map((curr, index) => {
      return {
        code: curr.code,
        label: `${curr.code} • ${curr.name}`,
        index,
      };
    });
    setLanguageList(langsList);
    setUrlQueryString(new URLSearchParams(location.search));
    setCurrencyList(currencies);
  }, []);

  useEffect(() => {
    if (currentCurrency) {
      const currency = currencyList.filter(curr => {
        return curr.code === currentCurrency.code;
      });
      if (currency[0]) {
        setSelectedCurrency({
          code: currency[0].code,
          index: currency[0].index,
        });
      }
    }
  }, [currentCurrency, currencyList]);

  useEffect(() => {
    if (languageList.length) {
      const language = languageList.filter(lang => {
        return lang.lang === currentLang;
      });
      setSelectedLanguage({
        icon: language[0].icon,
        lang: language[0].lang,
        shortLabel: language[0].shortLabel,
        index: language[0].index,
      });
    }

    //const userLanguage = getLangFromLocalStorage();
  }, [languageList, currentLang]);

  const getUrlWithParamsLangAndCurrency = (lang, currency) => {
    let url = translationsPaths[lang];

    url = urlParams?.country && lang === 'pt_BR' ? `${url}pt` : url;

    if (urlQueryString) {
      let strParams = '';
      if (urlQueryString.get('lang')) urlQueryString.set('lang', lang);
      if (urlQueryString.get('currency'))
        urlQueryString.set('currency', currency);

      // add country param to the new url
      url = urlParams?.country ? `${url}/${urlParams.country}` : url;

      // add other query string params to the new url
      strParams = urlQueryString.toString();
      url = strParams ? `${url}?${strParams}` : url;
    }

    return url;
  };

  const handleSubmit = () => {
    const newCurrency = getCurrencyByCode(selectedCurrency.code);
    let url = getUrlWithParamsLangAndCurrency(
      selectedLanguage.lang,
      newCurrency.code
    );

    store.dispatch(setLanguage(selectedLanguage.lang));
    localStorage.setItem('language', selectedLanguage.lang);

    store.dispatch(setCurrency(newCurrency));
    localStorage.setItem('currency', newCurrency.code);

    if (onSubmit) onSubmit();

    navigate(url, { replace: true });
  };

  const handleOpenMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClick = e => {
    e.stopPropagation();
  };

  if (
    !languageList.length ||
    !currencyList.length ||
    !selectedLanguage ||
    !selectedCurrency
  )
    return null;

  return (
    <Styled.LocaleOptionsContainer onClick={handleOpenMenu}>
      <Styled.MenuContainer
        open={menuOpen}
        ref={ref}
        darkFont={darkFont}
        data-test="LocaleOptions.menu"
      >
        <Styled.LangMenu hidden={blockLocaleOptions.language}>
          <Styled.Flag
            dangerouslySetInnerHTML={{ __html: selectedLanguage.icon }}
          ></Styled.Flag>
          <Styled.FlagLabel
            dangerouslySetInnerHTML={{ __html: selectedLanguage.shortLabel }}
          ></Styled.FlagLabel>
        </Styled.LangMenu>
        <Styled.Divider
          open={menuOpen}
          darkLine={darkFont}
          hidden={blockLocaleOptions.currency || blockLocaleOptions.language}
        ></Styled.Divider>
        <Styled.CurrencyMenu hidden={blockLocaleOptions.currency}>
          {selectedCurrency.code}
        </Styled.CurrencyMenu>
      </Styled.MenuContainer>

      {menuOpen && (
        <Styled.SelectorContainer>
          <Styled.ItemsContainer
            onClick={e => handleClick(e)}
            hidden={blockLocaleOptions.language}
          >
            <Styled.DropDownItemLabel>
              {TEXTS.LANGUAGE_LABEL}
            </Styled.DropDownItemLabel>
            <DropDown
              list={languageList || []}
              selectedIndex={selectedLanguage.index}
              onSelectItem={(item, index) => handleSelectLanguage(item, index)}
              customization={{
                DropDownContainer: Styled.DropDownContainer,
                DropDownListContainer: Styled.DropDownListContainer,
                DropDownList: Styled.DropDownList,
                DropDownItem: Styled.DropDownItem,
              }}
              testId="LocaleOptions.selectLanguage"
            ></DropDown>
          </Styled.ItemsContainer>

          <Styled.ItemsContainer hidden={blockLocaleOptions.currency}>
            <Styled.DropDownItemLabel>
              {TEXTS.CURRENCY_LABEL}
            </Styled.DropDownItemLabel>
            <DropDown
              list={currencyList || []}
              selectedIndex={selectedCurrency.index}
              onSelectItem={(item, index) => handleSelectCurrency(item, index)}
              customization={{
                DropDownContainer: Styled.DropDownContainer,
                DropDownListContainer: Styled.DropDownListContainer,
                DropDownList: Styled.DropDownList,
                DropDownItem: Styled.DropDownItem,
              }}
              testId="LocaleOptions.selectCurrency"
            ></DropDown>
          </Styled.ItemsContainer>

          <Styled.Button
            onClick={handleSubmit}
            type="button"
            data-test="LocaleOptions.submit"
          >
            {TEXTS.CONFIRM}
          </Styled.Button>
        </Styled.SelectorContainer>
      )}
    </Styled.LocaleOptionsContainer>
  );
};

export default LocaleOptions;

import styled from 'styled-components';

export const ButtonContainer = styled.div`
  svg,
  path {
    opacity: 1;
  }

  @media screen and (max-width: 720px) {
    .icon {
      top: 200px !important;
    }
  }
`;
